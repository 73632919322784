@import 'src/styles/responsive';

.actionContainer {
  margin-bottom: 8px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: flex-start;
  @include device(stdMdUp) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}

.headSelect {
  width: 76px;
  :global {
    .ant-select-selector {
      height: 26px !important;
    }
  }
}

.headContentSelect {
  display: flex;
  align-items: center;
  column-gap: 8px;
  flex: 1;
}

.headInfo {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--dark-04);
}

.headButton {
  flex: 1;
  display: flex;
  justify-content: center;
}

// Add a third empty column for spacing
.emptySpace {
  flex: 1;
}
